#seedPic{
    height: 250px;
    float: right;
}
#seedDiv{
width: 100%;
height: 310px;
}
@media screen and (min-width: 750px) {
    #seedPic{
        z-index:-1;
        float: right;
    }
}