.scrnsht {
  margin: 0px 10px 15px 10px;
  width: 95%;
}
#connectLink a {
  margin-right: 15px;
}
#portfolioBody{
  max-width: 800px
}
#appsList {
  color: rgb(255, 255, 255);
  min-height: 50px;
  padding: 10px 5px 10px 10px;
  margin-bottom: 15px;
}
#portfolioSite p {
  margin: 15px 15px 15px 15px;
}
#portfolioItem {
  border-top: black 1px solid;
  margin: 10px;
}
#toolKit {
  color: black;
  padding: 20px 20px 20px 20px;
  border-radius: 10px;
  background: linear-gradient(
    45deg,
    #e9a5a5,
    #b8c1c0,
    #aea2db,
    #23d5ab,
    #aea2db
  );
  background-size: 400% 400%;
  -webkit-animation: gradient 10s ease infinite;
  animation: gradient 10s ease infinite;
}
.toolkitBtn {
  background-color: #ffffff; /* Used if the image is unavailable */
  background-image: url(../../billie-holiday.png);
  background-position: left;
  background-repeat: repeat; /* Do not repeat the image */
  border: solid black 2px;
  font-weight: bold;
  letter-spacing: 0.03rem;
  padding: 7px 7px 7px 7px;
  margin: 5px 5px 5px 5px;
}
.toolkitBtn:hover {
  box-shadow: 0 5px 15px #3f3074;
  cursor: default;
}
@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
#mobileToolbox {
  display: none;
}
#desktopToolbox {
  display: inline;
}
#portfolioItem p {
  margin: 0px 10px 5px 10px;
  padding: 5px 10px 5px 5px;
}
#toolkit {
  text-align: left;
}
#toolkit li {
  padding: 0px 0px 0px 20px;
  z-index: 0;
  margin: 0px 0px 5px 0px;
}
.appLink {
  font-style: italic;
}
.portfBtnDiv {
  margin: auto;
  text-align: center;
}
.portfBtn {
  display: inline-block;
  margin: 0px 15px 10px 10px;
}
.subText {
  font-style: italic;
  color: rgb(116, 116, 116);
  font-size: small;
  margin: 0px 10px 0px 20px;
}
code {
  display: inline-block;
  margin: 0px 15px 0px 15px;
}
.pCode {
  display: inline-flex;
  margin: 0px 0px 0px 0px;
}
.portfolioArt {
  width: 45%;
  margin: 10px 10px 5px 10px;
  padding: 15px 15px 15px 15px;
  border: solid 1px #e3e3e3;
}
.pfVideo {
  width: 95%;
  height: 350px;
}
#artP,
#artSub {
  margin: 0px 0px 5px 15px;
}
@media screen and (max-width: 600px) {
  #mobileToolbox {
    display: block;
  }
  #desktopToolbox {
    display: none;
  }
}

@import url("https://fonts.googleapis.com/css?family=Lato:100&display=swap");

.btn {
  width: 180px;
  height: 60px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #91c9ff;
  outline: none;
  transition: 1s ease-in-out;
}

.btn:hover {
  transition: 1s ease-in-out;
  background: #4f95da;
}

.btn:hover svg {
  stroke-dashoffset: -480;
}

.btn span {
  color: white;
  font-size: 18px;
  font-weight: 100;
}
@media screen and (max-width: 600px) {
  #appsList,
  #artList #pf {
    width: 100%;
  }
  #portfolioItem {
    width: 95%;
  }
  #toolkit li {
    padding: 0px 0px 0px 5px;
  }

  #portfolioItem p,
  .pfDescrip,
  .portfolioArt,
  h3,
  p {
    margin: 0px 15px 5px 5px;
    padding: 5px 29px 5px 15px;
  }
  .portfolioArt {
    width: 95%;
    margin: 10px 10px 5px 10px;
    padding: 15px 15px 15px 15px;
    border: none;
  }
}
