.footer .fa-linkedin,
.footer .fa-flickr,
.footer .fa-instagram,
.footer .fa-github {
  background: rgb(61, 61, 61);
  color: white;
  width: 30px;
}
#footerFavicon {
  max-width: 35px;
padding: 0px 8px 0px 0px;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: rgb(61, 61, 61);
  color: rgb(175, 175, 175);
  padding: 15px 0px 0px 15px;
}
.footer p {
  background-color: rgb(61, 61, 61);
  margin-top: 15px;
}
@media screen and (min-width: 600px) {
  .footer{
    letter-spacing: 0.1rem;
  }
}
@media screen and (min-width: 400px) {
  .footer{
    letter-spacing: 0.05rem;
  }
}

@media screen and (max-width: 600px) {
  .footer p {
    margin-left: 8%;
    margin-right: 8%;
  }
  .footer{
    letter-spacing: none;

  }
}
@media screen and (max-width: 262px) {
  #mobileMorse {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  #cuteMorse {
    display: none;
  }
  #mobileMorse {
    display: inline-flex;
  }
  #publishDate {
    margin-right: 3px;
  }
  .footer {
    text-align: center;
    padding: 15px 0px 0px 0px;
  }
}