#about {
  max-width: 525px;
}
#self {
  width: 75%;
  max-width: 450px;
}
#about p {
  font-size: smaller;
}
#aboutmeIcons .fa-linkedin,
#aboutmeIcons .fa-flickr,
#aboutmeIcons .fa-instagram,
#aboutmeIcons .fa-github {
  color: rgb(255, 255, 255);
  width: 30px;
}
@media screen and (max-width: 600px) {
  #about{
    width: 99%;
    margin: 45px 0px 45px 0px;
  }
  #about p {
    margin: 10px 0px 10px 10px;
  }
  #self {
    margin: 10px 0px 10px 10px;
  }
}