#theNav {
  background-color: rgb(61, 61, 61);
  height: 70px;
}
.navbar {
  background-color: rgb(61, 61, 61);
  height: 70px;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  padding: 0px 10% 0px 10%;
}
.navbar li {
  background: rgb(61, 61, 61);
}
.navbar-light a {
  color: rgb(255, 255, 255);
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(255, 255, 255, 0.9);
  padding-left: 15px;
}
.navbar-light .navbar-nav .nav-link {
  color: rgb(152, 152, 152);
  padding-left: 15px;
}
#desktopLogo {
  width: 45px;
}
/* #spiral {
  stroke-width: 15;
  stroke-dasharray: 6025;
  stroke-dashoffset: 6025;
  animation: dash 30s linear alternate infinite;
} */
@keyframes dash {
  from {
    stroke-dashoffset: 6025;
  }
  to {
    stroke-dashoffset: 0;
  }
}
