@media screen and (min-width: 1000px) {
  p {
    font-size: medium;
  }
  .blogImg {
    width: 35%;
    float: right;
    margin: 0px 15px 10px 15px;
  }
}
#blog h1,
#blog p {
  text-align: left;
}
#blog p {
  margin: 10px 0px 20px 20px;
  width: 95%;
}
#break {
  text-align: center;
  letter-spacing: 0.3rem;
}
.blogPost {
  border-bottom: #989898 1px solid;
  margin: 10px;
  background-color: none;
  padding: 10px 5px 10px 5px;
  position: relative;
}
.blogPost text {
  color: rgb(116, 116, 116);
}
#previewText {
  font-style: italic;
  color: rgb(116, 116, 116);
  font-size: small;
}
.mutedBlog {
  font-style: italic;
  color: rgb(152, 152, 152);
  font-size: small;
}
#headContainer {
  display: flex;
  justify-content: space-between;
}
#blogDate {
  font-size: 12px;
}
.wideHeader {
  max-width: 450px;
  width: 95%;
  margin: 15px 15px 15px 15px;
}
.closePost {
  height: 34px;
  width: 34px;
  position: -webkit-sticky;
  position: sticky;
  float: right;
  top: 80px;
  padding: 6px 6px 6px 6px;
}
.openPost {
  padding: 4px 8px 4px 8px;
  position: absolute;
  bottom: 15px;
  right: 15px;
  border-radius: 8px;
}
.closePost,
.openPost {
  background: linear-gradient(
    45deg,
    #e9a5a5,
    #b8c1c0,
    #aea2db,
    #23d5ab,
    #aea2db
  );
  background-size: 400% 400%;
  -webkit-animation: gradient 10s ease infinite;
  animation: gradient 10s ease infinite;
  border: none;
  font-weight: bold;
  border-radius: 5px;
}
.blogPreviewImg {
  max-height: 450px;
  max-width: 450px;
  margin: 15px 15px 60px 15px;
}
#chipsPreview {
  width: 95%;
  float: none;
  max-height: 450px;
  max-width: 450px;
  margin: 15px 15px 60px 15px;
}
.blogHeadImg {
  max-height: 450px;
  max-width: 450px;
  float: right;
  margin: 15px 15px 15px 15px;
}
.blogImg {
  margin: 15px 18px 15px 18px;
  width: 40%;
  float: left;
}
#openPost {
  border: 1px rgb(196, 196, 196) solid;
}
.openPost:hover,
.closePost:hover {
  background-color: rgb(233, 233, 233);
  opacity: 0.5;
  border: none;
}
.blogPost:hover span:after {
  opacity: 1;
  right: 0;
}
@media screen and (max-width: 1400px) {
  #blog,
  .blogPost {
    width: 90%;
  }
}
@media screen and (max-width: 950px) {
  #sedona {
    width: 50%;
  }
}
@media screen and (max-width: 750px) {
  #previewText {
    padding-right: 15px;
    margin: 10px 10px 10px 10px;
  }
}
@media screen and (max-width: 600px) {
  .blogHeadImg {
    width: 35%;
  }
  .blogPreviewImg {
    max-height: none;
    max-width: none;
    width: 95%;
    float: none;
    margin: 5px 5px 60px 5px;
  }
  #chipsPreview {
    max-height: none;
    max-width: none;
    width: 99%;
    float: none;
    margin: 5px 0px 60px 0px;
  }
  #sedona{
    max-height: none;
    max-width: none;
    width: 100%;
    float: none;
    margin: 15px 15px 15px 15px;
    padding: 15px;
  }
  #blog {
    margin: 0px 0px 0px 0px;
    width: 100%;
  }
  #blog p,
  .blogPost {
    width: 99%;
    margin: 10px 0px 10px 0px;
  }
}
