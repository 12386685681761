#seasonBanner {
  margin: 5px 0px 0px 5px;
  color: black;
  padding: 3px 5px 3px 5px;
  font-weight: bold;
  opacity: .8;
  position: fixed;
  z-index: 1;
  top: 13%;
  right: 5%;
  width: 200px;
  border-radius: 5px;
  border: none;
  text-decoration: none;
  overflow: hidden;
  transition-duration: 0.4s;
  background-color: rgb(240, 239, 239);
  background: linear-gradient(225deg, #ffffff, #fca063, #ffffff, #faba6a);
  background-size: 400% 400%;
  -webkit-animation: gradient 10s ease infinite;
  animation: gradient 10s ease infinite;
}