@media screen and (min-width: 1000px) {
  .blogImg {
    width: 35%;
    float: right;
    margin: 0px 15px 10px 15px;
  }
}
#mongoexample, #blogtags, #dbAtlas{
  margin: 10px auto 10px auto;
  width: 95%;
}

/* ALERT: for some reason, nrfConnect won't work if it's an id */
.nrfConnect{
  margin: 15px 18px 15px 18px;
  height: 300px;
  float: left;
}