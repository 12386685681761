.App {
  text-align: left;
  background-color: rgb(45, 45, 45);
  color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
}
.standardButton {
  background-color: #cccccc;
  font-weight: bold;
  letter-spacing: 0.03rem;
  padding: 7px 7px 7px 7px;
  background: linear-gradient(
    45deg,
    #e9a5a5,
    #b8c1c0,
    #aea2db,
    #23d5ab,
    #aea2db
  );
  background-size: 400% 400%;
  -webkit-animation: gradient 10s ease infinite;
  animation: gradient 10s ease infinite;
  border: none;
  font-weight: bold;
  border-radius: 5px;
}
.mainBody, #blog {
  background-color: rgb(45, 45, 45, .85);
  border-radius: 10px;
  padding: 25px 25px 25px 25px;
}
.mainBody::after, #blog::after{
  content: 'photos: all taken by me unless otherwise stated or readily apparent.';
  color: rgb(198, 198, 198);
  display: inline-flex;
  margin-left: none;
  font-size: x-small;
  font-style: italic;
}
#container {
  padding: 15px 15px 15px 15px;
  margin: 0px 0px 60px 0px;
  position: absolute;
  left: 0;
  right: 0; 
  background-image: url("https://res.cloudinary.com/dgwzirogr/image/upload/v1628564534/portfolio-large/palisadelarge_ez67xu.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
#theseMine {
  color: rgb(198, 198, 198);
  display: inline-flex;
  margin-left: none;
}
p {
  font-size: 15px;
}
a{
  color: #8dccff;
}
#code {
  color: #129459;
  font-weight: bold;
  letter-spacing: 0.1em;
}
/* basically duct tape */
.ghostDiv {
  height: 300px;
  width: 30px;
}
.header{
  color: rgb(0, 0, 0);
}
.headHr {
  clear: both;
}
.footBanner {
  width: 70%;
  padding: 35px 0px 35px 0px;
  float: left;
  clear: both;
  display: block;
  margin: 0px 1% 0px 5%;
}
@media screen and (max-width: 650px) {
  .footBanner {
    width: 100%;
    margin: 0px 0px 0px 0px;
  }
}
@media screen and (max-width: 600px) {
  /* #container {
    padding: 5px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
  } */
  .mainBody {
    width: 98%;
    margin: 0px 0px 0px 0px;
    padding: 10px;
  }
  #theseMine {
    font-size: x-small;
  }
}
#logo {
  margin: none auto none auto;
  height: 7%;
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 100;
}
#logoBkgd {
  background-color: rgb(45, 45, 45);
  width: 100%;
  height: 80px;
}
