@import url(https://fonts.googleapis.com/css?family=Lato:100&display=swap);
body {
  margin: 0;
  margin-top:64px;
  padding: 0;
  font-family: 'Quicksand';
}
@font-face {
  font-family: 'Quicksand';
  src: local('Quicksand'), url(/static/media/Quicksand-Medium.74eb5c18.ttf) format('truetype');
}
h2 {
  text-align: left;
  padding-left: 10px;
  margin-top: 10px;
  text-align: center;
}
h3 {
  text-align: left;
  padding-left: 10px;
  margin-top: 10px;
  font-size: 20px;
}
html {
  scroll-behavior: smooth;
}
.App {
  text-align: left;
  background-color: rgb(45, 45, 45);
  color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
}
.standardButton {
  background-color: #cccccc;
  font-weight: bold;
  letter-spacing: 0.03rem;
  padding: 7px 7px 7px 7px;
  background: linear-gradient(
    45deg,
    #e9a5a5,
    #b8c1c0,
    #aea2db,
    #23d5ab,
    #aea2db
  );
  background-size: 400% 400%;
  -webkit-animation: gradient 10s ease infinite;
  animation: gradient 10s ease infinite;
  border: none;
  font-weight: bold;
  border-radius: 5px;
}
.mainBody, #blog {
  background-color: rgb(45, 45, 45, .85);
  border-radius: 10px;
  padding: 25px 25px 25px 25px;
}
.mainBody::after, #blog::after{
  content: 'photos: all taken by me unless otherwise stated or readily apparent.';
  color: rgb(198, 198, 198);
  display: inline-flex;
  margin-left: none;
  font-size: x-small;
  font-style: italic;
}
#container {
  padding: 15px 15px 15px 15px;
  margin: 0px 0px 60px 0px;
  position: absolute;
  left: 0;
  right: 0; 
  background-image: url("https://res.cloudinary.com/dgwzirogr/image/upload/v1628564534/portfolio-large/palisadelarge_ez67xu.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
#theseMine {
  color: rgb(198, 198, 198);
  display: inline-flex;
  margin-left: none;
}
p {
  font-size: 15px;
}
a{
  color: #8dccff;
}
#code {
  color: #129459;
  font-weight: bold;
  letter-spacing: 0.1em;
}
/* basically duct tape */
.ghostDiv {
  height: 300px;
  width: 30px;
}
.header{
  color: rgb(0, 0, 0);
}
.headHr {
  clear: both;
}
.footBanner {
  width: 70%;
  padding: 35px 0px 35px 0px;
  float: left;
  clear: both;
  display: block;
  margin: 0px 1% 0px 5%;
}
@media screen and (max-width: 650px) {
  .footBanner {
    width: 100%;
    margin: 0px 0px 0px 0px;
  }
}
@media screen and (max-width: 600px) {
  /* #container {
    padding: 5px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
  } */
  .mainBody {
    width: 98%;
    margin: 0px 0px 0px 0px;
    padding: 10px;
  }
  #theseMine {
    font-size: x-small;
  }
}
#logo {
  margin: none auto none auto;
  height: 7%;
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 100;
}
#logoBkgd {
  background-color: rgb(45, 45, 45);
  width: 100%;
  height: 80px;
}

#about {
  max-width: 525px;
}
#self {
  width: 75%;
  max-width: 450px;
}
#about p {
  font-size: smaller;
}
#aboutmeIcons .fa-linkedin,
#aboutmeIcons .fa-flickr,
#aboutmeIcons .fa-instagram,
#aboutmeIcons .fa-github {
  color: rgb(255, 255, 255);
  width: 30px;
}
@media screen and (max-width: 600px) {
  #about{
    width: 99%;
    margin: 45px 0px 45px 0px;
  }
  #about p {
    margin: 10px 0px 10px 10px;
  }
  #self {
    margin: 10px 0px 10px 10px;
  }
}
.scrnsht {
  margin: 0px 10px 15px 10px;
  width: 95%;
}
#connectLink a {
  margin-right: 15px;
}
#portfolioBody{
  max-width: 800px
}
#appsList {
  color: rgb(255, 255, 255);
  min-height: 50px;
  padding: 10px 5px 10px 10px;
  margin-bottom: 15px;
}
#portfolioSite p {
  margin: 15px 15px 15px 15px;
}
#portfolioItem {
  border-top: black 1px solid;
  margin: 10px;
}
#toolKit {
  color: black;
  padding: 20px 20px 20px 20px;
  border-radius: 10px;
  background: linear-gradient(
    45deg,
    #e9a5a5,
    #b8c1c0,
    #aea2db,
    #23d5ab,
    #aea2db
  );
  background-size: 400% 400%;
  -webkit-animation: gradient 10s ease infinite;
  animation: gradient 10s ease infinite;
}
.toolkitBtn {
  background-color: #ffffff; /* Used if the image is unavailable */
  background-image: url(/static/media/billie-holiday.69330180.png);
  background-position: left;
  background-repeat: repeat; /* Do not repeat the image */
  border: solid black 2px;
  font-weight: bold;
  letter-spacing: 0.03rem;
  padding: 7px 7px 7px 7px;
  margin: 5px 5px 5px 5px;
}
.toolkitBtn:hover {
  box-shadow: 0 5px 15px #3f3074;
  cursor: default;
}
@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
#mobileToolbox {
  display: none;
}
#desktopToolbox {
  display: inline;
}
#portfolioItem p {
  margin: 0px 10px 5px 10px;
  padding: 5px 10px 5px 5px;
}
#toolkit {
  text-align: left;
}
#toolkit li {
  padding: 0px 0px 0px 20px;
  z-index: 0;
  margin: 0px 0px 5px 0px;
}
.appLink {
  font-style: italic;
}
.portfBtnDiv {
  margin: auto;
  text-align: center;
}
.portfBtn {
  display: inline-block;
  margin: 0px 15px 10px 10px;
}
.subText {
  font-style: italic;
  color: rgb(116, 116, 116);
  font-size: small;
  margin: 0px 10px 0px 20px;
}
code {
  display: inline-block;
  margin: 0px 15px 0px 15px;
}
.pCode {
  display: inline-flex;
  margin: 0px 0px 0px 0px;
}
.portfolioArt {
  width: 45%;
  margin: 10px 10px 5px 10px;
  padding: 15px 15px 15px 15px;
  border: solid 1px #e3e3e3;
}
.pfVideo {
  width: 95%;
  height: 350px;
}
#artP,
#artSub {
  margin: 0px 0px 5px 15px;
}
@media screen and (max-width: 600px) {
  #mobileToolbox {
    display: block;
  }
  #desktopToolbox {
    display: none;
  }
}

.btn {
  width: 180px;
  height: 60px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #91c9ff;
  outline: none;
  transition: 1s ease-in-out;
}

.btn:hover {
  transition: 1s ease-in-out;
  background: #4f95da;
}

.btn:hover svg {
  stroke-dashoffset: -480;
}

.btn span {
  color: white;
  font-size: 18px;
  font-weight: 100;
}
@media screen and (max-width: 600px) {
  #appsList,
  #artList #pf {
    width: 100%;
  }
  #portfolioItem {
    width: 95%;
  }
  #toolkit li {
    padding: 0px 0px 0px 5px;
  }

  #portfolioItem p,
  .pfDescrip,
  .portfolioArt,
  h3,
  p {
    margin: 0px 15px 5px 5px;
    padding: 5px 29px 5px 15px;
  }
  .portfolioArt {
    width: 95%;
    margin: 10px 10px 5px 10px;
    padding: 15px 15px 15px 15px;
    border: none;
  }
}

@media screen and (min-width: 1000px) {
  p {
    font-size: medium;
  }
  .blogImg {
    width: 35%;
    float: right;
    margin: 0px 15px 10px 15px;
  }
}
#blog h1,
#blog p {
  text-align: left;
}
#blog p {
  margin: 10px 0px 20px 20px;
  width: 95%;
}
#break {
  text-align: center;
  letter-spacing: 0.3rem;
}
.blogPost {
  border-bottom: #989898 1px solid;
  margin: 10px;
  background-color: none;
  padding: 10px 5px 10px 5px;
  position: relative;
}
.blogPost text {
  color: rgb(116, 116, 116);
}
#previewText {
  font-style: italic;
  color: rgb(116, 116, 116);
  font-size: small;
}
.mutedBlog {
  font-style: italic;
  color: rgb(152, 152, 152);
  font-size: small;
}
#headContainer {
  display: flex;
  justify-content: space-between;
}
#blogDate {
  font-size: 12px;
}
.wideHeader {
  max-width: 450px;
  width: 95%;
  margin: 15px 15px 15px 15px;
}
.closePost {
  height: 34px;
  width: 34px;
  position: -webkit-sticky;
  position: sticky;
  float: right;
  top: 80px;
  padding: 6px 6px 6px 6px;
}
.openPost {
  padding: 4px 8px 4px 8px;
  position: absolute;
  bottom: 15px;
  right: 15px;
  border-radius: 8px;
}
.closePost,
.openPost {
  background: linear-gradient(
    45deg,
    #e9a5a5,
    #b8c1c0,
    #aea2db,
    #23d5ab,
    #aea2db
  );
  background-size: 400% 400%;
  -webkit-animation: gradient 10s ease infinite;
  animation: gradient 10s ease infinite;
  border: none;
  font-weight: bold;
  border-radius: 5px;
}
.blogPreviewImg {
  max-height: 450px;
  max-width: 450px;
  margin: 15px 15px 60px 15px;
}
#chipsPreview {
  width: 95%;
  float: none;
  max-height: 450px;
  max-width: 450px;
  margin: 15px 15px 60px 15px;
}
.blogHeadImg {
  max-height: 450px;
  max-width: 450px;
  float: right;
  margin: 15px 15px 15px 15px;
}
.blogImg {
  margin: 15px 18px 15px 18px;
  width: 40%;
  float: left;
}
#openPost {
  border: 1px rgb(196, 196, 196) solid;
}
.openPost:hover,
.closePost:hover {
  background-color: rgb(233, 233, 233);
  opacity: 0.5;
  border: none;
}
.blogPost:hover span:after {
  opacity: 1;
  right: 0;
}
@media screen and (max-width: 1400px) {
  #blog,
  .blogPost {
    width: 90%;
  }
}
@media screen and (max-width: 950px) {
  #sedona {
    width: 50%;
  }
}
@media screen and (max-width: 750px) {
  #previewText {
    padding-right: 15px;
    margin: 10px 10px 10px 10px;
  }
}
@media screen and (max-width: 600px) {
  .blogHeadImg {
    width: 35%;
  }
  .blogPreviewImg {
    max-height: none;
    max-width: none;
    width: 95%;
    float: none;
    margin: 5px 5px 60px 5px;
  }
  #chipsPreview {
    max-height: none;
    max-width: none;
    width: 99%;
    float: none;
    margin: 5px 0px 60px 0px;
  }
  #sedona{
    max-height: none;
    max-width: none;
    width: 100%;
    float: none;
    margin: 15px 15px 15px 15px;
    padding: 15px;
  }
  #blog {
    margin: 0px 0px 0px 0px;
    width: 100%;
  }
  #blog p,
  .blogPost {
    width: 99%;
    margin: 10px 0px 10px 0px;
  }
}

#seedPic{
    height: 250px;
    float: right;
}
#seedDiv{
width: 100%;
height: 310px;
}
@media screen and (min-width: 750px) {
    #seedPic{
        z-index:-1;
        float: right;
    }
}
@media screen and (min-width: 1000px) {
  .blogImg {
    width: 35%;
    float: right;
    margin: 0px 15px 10px 15px;
  }
}
#chipsBread {
  max-width: 450px;
  float: right;
  margin: 10px 10px 20px 25px;
}
#odas,
#ardIDE {
  margin: 10px 10px 20px 20px;
  width: 95%;
}

@media screen and (min-width: 1000px) {
  .blogImg {
    width: 35%;
    float: right;
    margin: 0px 15px 10px 15px;
  }
}
#picoBlink{
  float: left;
}
@media screen and (min-width: 1000px) {
  .blogImg {
    width: 35%;
    float: right;
    margin: 0px 15px 10px 15px;
  }
}
#mongoexample, #blogtags, #dbAtlas{
  margin: 10px auto 10px auto;
  width: 95%;
}

/* ALERT: for some reason, nrfConnect won't work if it's an id */
.nrfConnect{
  margin: 15px 18px 15px 18px;
  height: 300px;
  float: left;
}
@media screen and (min-width: 1000px) {
  .blogImg {
    width: 35%;
    float: right;
    margin: 0px 15px 10px 15px;
  }
}
#koansImg{
  margin: 10px auto 10px auto;
  width: 95%;
}
#spiral {
  stroke-width:15;
  stroke-dasharray: 6025;
  stroke-dashoffset: 6025;
  -webkit-animation: dash 30s linear alternate infinite;
          animation: dash 30s linear alternate infinite;
}
@-webkit-keyframes dash {
  from {
    stroke-dashoffset: 6025;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  from {
    stroke-dashoffset: 6025;
  }
  to {
    stroke-dashoffset: 0;
  }
}
#farmBio{
    margin-left: 50px;
}
#storyMap{
    width: 100%;
    height: 450px;
}
#farmLink{
text-align: right;
}


#theNav {
  background-color: rgb(61, 61, 61);
  height: 70px;
}
.navbar {
  background-color: rgb(61, 61, 61);
  height: 70px;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  padding: 0px 10% 0px 10%;
}
.navbar li {
  background: rgb(61, 61, 61);
}
.navbar-light a {
  color: rgb(255, 255, 255);
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(255, 255, 255, 0.9);
  padding-left: 15px;
}
.navbar-light .navbar-nav .nav-link {
  color: rgb(152, 152, 152);
  padding-left: 15px;
}
#desktopLogo {
  width: 45px;
}
/* #spiral {
  stroke-width: 15;
  stroke-dasharray: 6025;
  stroke-dashoffset: 6025;
  animation: dash 30s linear alternate infinite;
} */
@-webkit-keyframes dash {
  from {
    stroke-dashoffset: 6025;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  from {
    stroke-dashoffset: 6025;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.footer .fa-linkedin,
.footer .fa-flickr,
.footer .fa-instagram,
.footer .fa-github {
  background: rgb(61, 61, 61);
  color: white;
  width: 30px;
}
#footerFavicon {
  max-width: 35px;
padding: 0px 8px 0px 0px;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: rgb(61, 61, 61);
  color: rgb(175, 175, 175);
  padding: 15px 0px 0px 15px;
}
.footer p {
  background-color: rgb(61, 61, 61);
  margin-top: 15px;
}
@media screen and (min-width: 600px) {
  .footer{
    letter-spacing: 0.1rem;
  }
}
@media screen and (min-width: 400px) {
  .footer{
    letter-spacing: 0.05rem;
  }
}

@media screen and (max-width: 600px) {
  .footer p {
    margin-left: 8%;
    margin-right: 8%;
  }
  .footer{
    letter-spacing: none;

  }
}
@media screen and (max-width: 262px) {
  #mobileMorse {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  #cuteMorse {
    display: none;
  }
  #mobileMorse {
    display: inline-flex;
  }
  #publishDate {
    margin-right: 3px;
  }
  .footer {
    text-align: center;
    padding: 15px 0px 0px 0px;
  }
}
#scrollUp {
  background: white;
  border-radius: 8px;
  border: 1px solid #dddddd;
  width: 45px;
  opacity: 0.75;
}
.scrollTop {
  position: fixed;
  bottom: 70px;
  right: 100px;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: pointer; /* Safari */
  transition-duration: 1s;
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

.scrollTop:hover {
  opacity: 1;
}

@media screen and (max-width: 900px) {
  .scrollTop {
    right: 30px;
  }
}
@media screen and (max-width: 450px) {
  .scrollTop {
    right: 10px;
  }
}

#seasonBanner {
  margin: 5px 0px 0px 5px;
  color: black;
  padding: 3px 5px 3px 5px;
  font-weight: bold;
  opacity: .8;
  position: fixed;
  z-index: 1;
  top: 13%;
  right: 5%;
  width: 200px;
  border-radius: 5px;
  border: none;
  text-decoration: none;
  overflow: hidden;
  transition-duration: 0.4s;
  background-color: rgb(240, 239, 239);
  background: linear-gradient(225deg, #ffffff, #fca063, #ffffff, #faba6a);
  background-size: 400% 400%;
  -webkit-animation: gradient 10s ease infinite;
  animation: gradient 10s ease infinite;
}
